import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { logEvent } from '@angular/fire/analytics';
import { Capacitor } from '@capacitor/core';
import {
  ConsentStatus,
  ConsentType,
  FirebaseAnalytics,
} from '@capacitor-firebase/analytics';
import { jwtDecode } from 'jwt-decode';

import { IDecodedCmsToken, IMe } from '@lancelot-frontend/api';
import { LangService } from '@lancelot-frontend/core';
import { EnvironmentService } from '@lancelot-frontend/environment';

type DataLayer = Record<string, unknown>[];

declare const window: Window & {
  axeptioSDK?: Record<any, any>;
  dataLayer?: DataLayer;
};

type TUserProperties = {
  club: string | undefined;
  committee: string | undefined;
  is_1st_series: 'NO' | 'YES' | undefined;
  is_2nd_series: 'NO' | 'YES' | undefined;
  is_3rd_series: 'NO' | 'YES' | undefined;
  is_4th_series: 'NO' | 'YES' | undefined;
  is_high_level_player: 'NO' | 'YES' | undefined;
  is_new_licensee: 'NO' | 'YES' | undefined;
  is_player: 'NO' | 'YES' | undefined;
  is_pro: 'NO' | 'YES' | undefined;
};

@Injectable({ providedIn: 'root' })
export class AppAnalyticsService {
  isPlatformServer = isPlatformServer(inject(PLATFORM_ID));
  isNativePlatform = Capacitor.isNativePlatform();
  private environmentService = inject(EnvironmentService);
  private langService = inject(LangService);

  dataLayer?: object[];

  constructor() {
    if (!this.isNativePlatform && !this.isPlatformServer) {
      this.dataLayer = window.dataLayer = window.dataLayer || [];
    }
  }

  openAxeptioCookies() {
    if (!this.isPlatformServer) {
      if (window.axeptioSDK) {
        try {
          // @ts-expect-error openAxeptioCookies is defined by Axeptio script
          openAxeptioCookies();
        } catch {
          // NOOP
        }
      } else {
        // Add script to manage cookies with Axeptio
        const loadAxeptioScript = document.createElement('script');
        loadAxeptioScript.text = `
        window.axeptioSettings = {
          clientId: '${this.environmentService?.get('axeptio')?.clientId}',
          cookiesVersion: 'ffbridge-mobile-${this.langService.getActiveLang()}',
          googleConsentMode: {
            default: {
              analytics_storage: "denied",
              ad_storage: "denied",
              ad_user_data: "denied",
              ad_personalization: "denied",
              wait_for_update: 500
            }
          }
        };

        (function (d, s) {
          var t = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          e.async = true;
          e.src = 'https://static.axept.io/sdk.js';
          t.parentNode.insertBefore(e, t);
        })(document, 'script');
      `;
        document.body.appendChild(loadAxeptioScript);
      }
    }
  }

  setAnalyticsCollectionEnabled(enabled: boolean) {
    if (this.isNativePlatform) {
      FirebaseAnalytics.setEnabled({ enabled });
    }
  }

  setConsent({
    ad_personalization,
    ad_storage,
    ad_user_data,
    analytics_storage,
  }: {
    ad_personalization: 'denied' | 'granted';
    ad_storage: 'denied' | 'granted';
    ad_user_data: 'denied' | 'granted';
    analytics_storage: 'denied' | 'granted';
  }) {
    if (this.isNativePlatform) {
      FirebaseAnalytics.setConsent({
        type: ConsentType.AdPersonalization,
        status:
          ad_personalization === 'granted'
            ? ConsentStatus.Granted
            : ConsentStatus.Denied,
      });
      FirebaseAnalytics.setConsent({
        type: ConsentType.AdStorage,
        status:
          ad_storage === 'granted'
            ? ConsentStatus.Granted
            : ConsentStatus.Denied,
      });
      FirebaseAnalytics.setConsent({
        type: ConsentType.AdUserData,
        status:
          ad_user_data === 'granted'
            ? ConsentStatus.Granted
            : ConsentStatus.Denied,
      });
      FirebaseAnalytics.setConsent({
        type: ConsentType.AnalyticsStorage,
        status:
          analytics_storage === 'granted'
            ? ConsentStatus.Granted
            : ConsentStatus.Denied,
      });
    }

    this.setAnalyticsCollectionEnabled(analytics_storage === 'granted');
  }

  setUser(user: IMe | null | undefined) {
    const user_id = user?.firebaseUid;

    if (this.isNativePlatform) {
      FirebaseAnalytics.setUserId({ userId: user_id || null });
    } else if (this.dataLayer) {
      this.dataLayer.push({ user_id });
    }

    const userProperties: TUserProperties = {
      is_player: undefined,
      is_pro: undefined,
      is_new_licensee: undefined,
      is_4th_series: undefined,
      is_3rd_series: undefined,
      is_2nd_series: undefined,
      is_1st_series: undefined,
      is_high_level_player: undefined,
      club: undefined,
      committee: undefined,
    };

    if (user?.cmsToken) {
      try {
        const {
          cms: { clubs, committees, iv, roles },
        } = jwtDecode<IDecodedCmsToken>(user.cmsToken);

        userProperties.is_player = roles.includes('ROLE_PLAYER') ? 'YES' : 'NO';
        userProperties.is_pro = roles.includes('ROLE_PRO') ? 'YES' : 'NO';

        if (iv) {
          userProperties.is_new_licensee = iv === 20 ? 'YES' : 'NO';
          userProperties.is_4th_series = iv > 20 && iv <= 30 ? 'YES' : 'NO';
          userProperties.is_3rd_series = iv > 30 && iv <= 40 ? 'YES' : 'NO';
          userProperties.is_2nd_series = iv > 40 && iv <= 60 ? 'YES' : 'NO';
          userProperties.is_1st_series = iv > 60 ? 'YES' : 'NO';
          userProperties.is_high_level_player = iv >= 88 ? 'YES' : 'NO';
        }

        if (clubs[0]) {
          userProperties.club = clubs[0];
        }

        if (committees[0]) {
          userProperties.committee = committees[0];
        }
      } catch {
        // Just ignore user properties if we are not able to get it
      }
    }

    if (this.isNativePlatform) {
      Object.entries(userProperties).forEach(([key, value = null]) => {
        FirebaseAnalytics.setUserProperty({
          key,
          value,
        });
      });
    } else if (this.dataLayer) {
      this.dataLayer.push(userProperties);
    }
  }

  logEvent(
    name: Parameters<typeof logEvent>[1],
    params: Parameters<typeof logEvent>[2] = {},
  ) {
    if (this.isNativePlatform) {
      FirebaseAnalytics.logEvent({ name, params });
    } else if (this.dataLayer) {
      this.dataLayer.push({
        event: name,
        ...params,
      });
    }
  }
}
